import React, { useState } from "react"
import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import CanHelp from "../components/Home/CanHelp/CanHelpStyleThree"
import EastSchedule from '../components/Static/EastScheduleTwo/EastScheduleTwo'
import MGFav from '../components/Static/MgFavourites/MgFavouritesLocalLifeOthers'
import MGComments from '../components/LocalLoveComments'
import Filter from '../components/Static/StaticBanner/LocalLoveFilter'
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby";

const LocalLifeDetail = (props) => {
    const data = props.data.strapiRestaurants
    const data_gallery = props.data.strapidata
    const res = props.data.allStrapiRestaurants.edges
    const commites = props.data.allStrapiLocalLifeComments.edges
    const list = props.data.allStrapiLocalLifeCategories.edges
    
    const [ commitCount, SetCommitCount] = useState();


    var area_list = [],
    filter_resturant = [],
    filter_resturant_cuisine = []
    for (var i = 0; i < props.data.allStrapiAreaGuides.edges.length; i++) {
      area_list.push(props.data.allStrapiAreaGuides.edges[i].node)
    }

    let areas_list = area_list.filter(
      (ele, ind) =>
        ind === area_list.findIndex(elem => elem.strapiId === ele.strapiId)
    )
    
   


	return (
		<div className="local-life-details-page">
	        <HeaderTwo />
			
			<SEO location={props.location} title={data.Meta_Title ? data.Meta_Title : data.Name} description={data.Meta_Description ? data.Meta_Description : data.Name} />

	        <div className="content">
	        <EastSchedule commitCount={commitCount} data={data} category={data.Category ? data.Category.Name : ''} Title={data.Name} gallery={data_gallery} />

          <MGComments  locallovename={data.Name} locallovestrapiid={data.strapiId} SetCommitCount ={SetCommitCount}/>

	        <MGFav commites={commites} data={res} category={data.Category ? data.Category.Name : ''} Title={data.area_guide ? data.area_guide.Title : ''} url={data.area_guide ? data.area_guide.URL : ''}/>

          <Filter area_drop_down={areas_list}  list={list} />

	        <CanHelp />
	        </div>
	        <Footer Select_Popular_Search="Static_Contact" popularSearch="static"/>
		</div>
	)
}

export default LocalLifeDetail

export const pageQuery = graphql`query LocalLifeDetailQuery($slug: String!, $url: String!, $category: String!, $strapiId: ID!) {
  strapiRestaurants(URL: {eq: $slug}) {
    About_Restaurant
    Address
    EatIn
    Takeaways
    Featured
    Email
    Meta_Description
    Meta_Title
    Name
    Offer_MSG
    Phone
    URL
    strapiId
    Category {
      Name
    }
    area_guide {
      URL
      Title
    }
    Gallery {
      Image {
        formats {
          large {
            childImageSharp {
              gatsbyImageData(
                formats: AUTO
                width: 450
                quality: 100
                layout: FIXED
                transformOptions: {cropFocus: CENTER, fit: COVER}
              )
            }
          }
        }
      }
    }
  }
  strapidata {
    restaurant(id: $strapiId) {
      Gallery {
        Image {
          formats
        }
        Embed_Video_URL
      }
    }
  }
  allStrapiAreaGuides(filter: {Publish: {eq: true}}) {
    edges {
      node {
        Title
        URL
        strapiId
      }
    }
  }
  allStrapiLocalLifeCategories(filter: {local_life_things_to_dos: {elemMatch: {Featured: {eq: true}}}, Publish: {eq: true}}, sort: {fields: Sort, order: ASC}) {
    edges {
      node {
        Sort
        Name
        URL
        Alias
        strapiId
        local_life_things_to_dos {
          area_guide
        }
      }
    }
  }
  allStrapiLocalLifeComments(
    filter: {status: {eq: "Approved"}}
    limit: 99999
    sort: {fields: createdAt, order: DESC}
  ) {
    edges {
      node {
        name
        comments
        local_life_things_to_do {
          id
        }
      }
    }
  }
  allStrapiRestaurants(
    filter: {Publish: {eq: true}, area_guide: {URL: {eq: $url}}, Category: {URL: {eq: $category}}, URL: {ne: $slug}}
  ) {
    edges {
      node {
        URL
        Name
        Tile_Image_Label
        Offer_MSG
        Tile_Image {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 400, layout: CONSTRAINED)
          }
        }
        Short_Description
        Address
        Category {
          URL
        }
        Featured
      }
    }
  }
}
`
