import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React,{useEffect, useState} from "react";
import {Col, Row, Container, Button } from 'react-bootstrap';
import VideoCard from '../VideoCard/VideoCard';
import  "./EastScheduleTwo.scss";
import navLogo from "../../../images/my-nav-logo.svg"
import profImg2 from "../../../images/profile-img02.png"
import eastMainTwo from "../../../images/east-main-video2.jpg";
import thumbImg01 from "../../../images/thum-video-img01.jpg"
import thumbImg02 from "../../../images/thum-video-img02.jpg"
import thumbImg03 from "../../../images/thum-video-img03.jpg"
import thumbImg04 from "../../../images/thum-video-img04.jpg"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ReactMarkdown from "react-markdown"
import VideoPlay from "../../Play/custom-video";
import Breadcrumbs from "../../MgServices/Breadcrumb/news-breadcrumb";
import { navigate, useLocation } from "@reach/router"
import axios from 'axios';
import ModalTiny from "react-bootstrap/Modal"
import CommentForm from "../../forms/comments-reviews"
import logoImgLove from "../../../images/nav-logo-love-new.svg";
import SocialShare from "../../utils/socialShare";


const EastScheduleTwo=(props)=>{
  const [isPlay,setPlay] = useState(false);

  const [likesicon,setLikesicon] = useState("icon-heart-likes");//like icon
  const [ip, setIP] = useState('');//client ip value
  const [likescount,setLikescount] = useState(0); // like count of this current article
  const [likesarr,setLikesarr] = useState([]); // total likes in array format
  const [ipliked,setIpliked] = useState(0); // flag whether liked or not
  const [igotcount,setIgotcount] = useState(0); // flag whether get the like count or not
  const [likestrapiid,setLikestrapiid] = useState(''); // article's trapi id for delete mutation

  const shareurl = typeof window !== 'undefined' ? window.location.href : ''

  const [Shareicons,setShareicons] = React.useState(false);
  const openShareicons = () => {
    setShareicons(true);
    if(Shareicons === true) {
      setShareicons(false);
    }
  }

  const [modalCommentformOpen,setCommentformOpen] = React.useState(false);
  const openCommentformModal = () => {
      setCommentformOpen(true);
  }
  const closeCommentformModal = () => {
      setCommentformOpen(false);
  }


  // strapi axios - start
  const baseURL = `${process.env.GATSBY_STRAPI_FORM_URL}`;
  const token = process.env.GATSBY_STRAPI_FORM_TOKEN;

  const authInstance = axios.create({
    baseURL: baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": 'application/json' // we are sending json format to graphql
    },
  });
  // strapi axios - eend

  //function to load ip address from the API
  const getIPData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
  }

  //function to get likes count of the current article
  const getLikesCount = async (myURL) => {
  var mypostdata = JSON.stringify({"query":"query myquery($url: String) { localLifeLikes (where: {local_life_things_to_do: {URL: $url} } ) { id, ip_address } }","variables":{"url": myURL}});

    authInstance.post('graphql',mypostdata).then(response => {
      setLikescount(response?.data.data?.localLifeLikes?.length);
      setLikesarr(response?.data.data?.localLifeLikes)
      setIgotcount(1)
    });
  }

  //function to do delete mutation of like data
  const deleteLike = async (myId) => {
  var mypostdata = JSON.stringify({"query":"mutation deleteLikes ($id: ID!) { deleteLocalLifeLike(input: { where: { id: $id } }) { localLifeLike { id } } }","variables":{"id": myId}});

    authInstance.post('graphql',mypostdata).then(response => {
      console.log("likes removed")
      setIpliked(2)
    });
  }

  //function to do call create mutation like record
  const addLike = async (ipaddr, strapiid) => {
  var mypostdata = JSON.stringify({"query":"mutation myaddlikes ($ipaddr: String!, $strapiid: ID) { createLocalLifeLike(input: { data: { ip_address: $ipaddr, local_life_things_to_do: $strapiid } }) { localLifeLike { id } } }","variables":{"ipaddr": ipaddr, "strapiid": strapiid}});

    authInstance.post('graphql',mypostdata).then(response => {
      console.log("likes added");
      setIpliked(1)
      setLikestrapiid(response?.data?.data?.createLocalLifeLike?.localLifeLike?.id)
    });
  }

  //function to cross check whether this ip liked this article
  const chkLiked = (ip, likesarr) => {
    let fillheart = likesarr.some(function(item) {
      return item.ip_address == ip
    });
    if(fillheart){
      setLikesicon("icon-heart-likes-fill");
      setIpliked(1); // 1 means liked
    } else {
      setIpliked(2); // 2 means like removed
    }
    likesarr.some(function(item) {
      if( item.ip_address == ip ) {
        setLikestrapiid(item.id) // we need this id for deletion
      }
    });
  }

  useEffect( () => {
    if(!ip) {
      getIPData()
    }
    if(igotcount == 0){
      getLikesCount(props.data.URL)
    }
    if(ipliked == 0 && ip && igotcount == 1) {
      chkLiked(ip, likesarr)
    }
  }, [props.data.URL, ip, ipliked, likesarr])

  const likesaction = () => {
    if(ipliked == 1){ // liked already
      setLikescount(likescount - 1)
      setLikesicon("icon-heart-likes");
      if(likestrapiid) {
        deleteLike(likestrapiid)
      }
      setIpliked(2) // 2 means like removed
    }
    if(ipliked == 2){
      setLikesicon("icon-heart-likes-fill");
      setLikescount(likescount + 1)
      if(ip && props.data.strapiId) {
        addLike(ip, props.data.strapiId)
      }
      setIpliked(1) // 1 means liked
    }
  }

  const location = useLocation();
    
  const shareUrl = location.href

  const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });    
    }

    var mygalleruimgs = []
    // console.log("ccc", props?.gallery?.restaurant?.Gallery)
    for (let i = 0; i < props?.gallery?.restaurant?.Gallery?.length; i++) {
      for (let j = 0; j < props?.gallery?.restaurant?.Gallery[i]?.Image?.length; j++) {
        if(props?.gallery?.restaurant?.Gallery[i]?.Image[j]?.formats?.small?.url){
          mygalleruimgs.push({ "imgUrl":props?.gallery?.restaurant?.Gallery[i]?.Image[j]?.formats?.small?.url, "videoUrl": props?.gallery?.restaurant?.Gallery[i].Embed_Video_URL})
        } else {
          mygalleruimgs.push({ "imgUrl": props?.gallery?.restaurant?.Gallery[i]?.Image[j]?.formats?.thumbnail?.url, "videoUrl": props?.gallery?.restaurant?.Gallery[i].Embed_Video_URL})
        }
      }
    }

    const hanldeClick = () => {
      navigate('/about/coupon-code/')
    }

    const hanldeClickCommentIcon = () => {
        var idelement = "comments_section"
        setTimeout(function() {
          var elmnt = document.getElementById(idelement.toString());
          if(elmnt){
          elmnt.scrollIntoView();
          }
        }, 1);
    }

  return (
    <section className="east-schedule-two">
      <Container className="container-east-schedule">
      
        <Row>
        <Col lg={12} className="d-none d-md-block">
        <Breadcrumbs url="about" tag={props.category} category={props.category} Title={props.Title} />
        </Col>
        <Col xl={6} className="order-xl-2">
        <Carousel className="main-video" showArrows={0} autoPlay={0} interval={3000} infiniteLoop={1} showStatus={1} showIndicators={1} showThumbs={1} useKeyboardArrows={0} stopOnHover={0} swipeable={1} >
          {mygalleruimgs.sort((a, b) => b.videoUrl - a.videoUrl).map((item) => {
            return (
              <figure>
                   {item.imgUrl ? <img loading="lazy" src={item.imgUrl} alt={`${props.data.Name ? props.data.Name : ''} - Martyn Gerrard`} /> : '' }
                   {item.videoUrl && <VideoPlay title={`Review`} changeIcon={"local_love_icon-play-icon"} url={item.videoUrl}/> }
              </figure>
            );
          })}
        </Carousel>
          </Col>
          <Col xl={6} className="order-xl-1">
            <Col lg={12} className="d-block d-md-none px-0">
            <Breadcrumbs url="about" tag={props.category} category={props.category} Title={props.Title} />
            </Col>

          {/* <Link to="/about/loving-life/" className="back-to text-uppercase"><span>&lt; Back to</span>
                    <img className="back-to-logo" src={logoImgLove} alt="local loves logo"></img></Link> */}
          <h2 className="sec-title d-md-block black-diamond-font">{props.data.Name ? props.data.Name : ''}</h2>
          <a className="contact-no" href={`tel:${props.data.Phone}`}>{props.data.Phone}</a>
          {props.data.Address ? <p className="add-txt">{props.data.Address}</p> : '' }
          {/* {props.data.Offer_MSG ? <div className="discount-off">
              <p className="discount-txt text-uppercase"><img loading="lazy" src={navLogo} alt="logo"/> {props.data.Offer_MSG}</p>
          </div> : ''
          } */}
          {
            props.data.Offer_MSG &&  <Button onClick={hanldeClick} className="btn btn-pink" type="submit">
            {props.data.Offer_MSG}
          </Button>
          }
          <div className="share-print">
            {props.data.EatIn === true ?
              <span to="#"><i className="icon-right-tick"></i>Eat In</span> : '' }
            {props.data.Takeaways === true ?
              <span to="#"><i className="icon-right-tick"></i>Takeaways</span> : '' }

              {props.data.Cuisine ? <span to="#"><i className="icon-pizza-food"></i>{props.data.Cuisine.Name}</span> : '' }
              
              <span onClick={openShareicons} className="share"><i className="icon-share"></i>Share
              {Shareicons &&
                <SocialShare openShareicons={openShareicons} />
              }</span>
              <span className="comment_icon" onClick={hanldeClickCommentIcon} >
                <i className="icon-comment-blue" ></i><span>{props.commitCount} Comments</span>
              </span>

              <span onClick={likesaction} className="locallovelikes mt-4">Show this local business some love and like it  <i className={`${likesicon} ml-3`} ></i>{likescount} Likes</span>

              {props.data.File ?
              <a href={props.data.File.publicURL} target="_blank"><i className="icon-print"></i>Print</a> : '' }
          </div>
          <ReactMarkdown source={props.data.About_Restaurant} allowDangerousHtml className="about-content" />
          
          {/* don't show comment button if category is Clubs & Events */}
          {props.category !== "Clubs & Events" && <div className="btns-wrp d-md-flex align-items-center">
              <Link className="btn comment_btn" to="#" onClick={()=> { openCommentformModal();trackerShare('Comments')}}>
                  add your comment about this business
              </Link>
          </div>}
          
          </Col>
        </Row>
      </Container>
      <ModalTiny backdrop="static" show={modalCommentformOpen} onHide={closeCommentformModal} className="book-a-viewing-modal share-to-friend-modal">
        <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">ADD COMMENTS</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section"><CommentForm local_life_id={props.data.strapiId} local_life_name={props.data.Name} /></ModalTiny.Body>
      </ModalTiny>
    </section>
  );
  }

export default EastScheduleTwo
