import { useStaticQuery, graphql } from "gatsby";
import React,{useEffect, useState} from "react";
import {Col, Row, Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import { Link } from "@StarberryUtils";
import ShowMoreText from 'react-show-more-text';
import ModalTiny from "react-bootstrap/Modal"
import CommentForm from "./forms/comments-reviews"
import { useLocation } from "@reach/router"
import Slider from 'react-slick';
import CommentIconGroup from '../images/comment_icon_group.svg'

const LocalLifeComments=(props)=> {

  const data = useStaticQuery(graphql`{
    allStrapiLocalLifeComments(
      filter: {status: {eq: "Approved"}}
      limit: 99999
      sort: {fields: createdAt, order: DESC}
    ) {
      edges {
        node {
          name
          comments
          local_life_things_to_do {
            id
          }
        }
      }
    }
  }
`);

    const settings =  {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            dots: true,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            dots: true,
          },
        },
      ],
    }

    const list = data.allStrapiLocalLifeComments.edges

    var count = list.filter(({node}) => node.local_life_things_to_do?.id == props.locallovestrapiid).length

    // console.log(count, "count")
    props.SetCommitCount(count)

    const [modalCommentformOpen,setCommentformOpen] = React.useState(false);
    const openCommentformModal = () => {
        setCommentformOpen(true);
    }
    const closeCommentformModal = () => {
        setCommentformOpen(false);
    }

  const location = useLocation();
    
  const shareUrl = location.href

  const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Share - social icons',
          'formType': event + ' - ' +shareUrl,
          'formId': 'Share',
          'formName': 'Share',
          'formLabel': 'Share'
        });    
    }

    let cmtsCnt = 0;
    list.map((node) => {
      if(node?.node?.local_life_things_to_do?.id && node?.node?.local_life_things_to_do?.id == props.locallovestrapiid){
        cmtsCnt = cmtsCnt + 1;
      }
    });
    if(cmtsCnt == 0)
      return null;

      return (
        <div id="comments_section" className="mg-favourites mg-fav-others loving-life-comments">
        <div className="container">
          <div className="local-life-new-page-listing local-life-area-landing-page    ">
                  <Row>
                      <Col md={8}>
                          <h2 className="sec-title">Customer Comments</h2>
                      </Col>
                      <Col md={4} className="d-none d-md-block">
                          <Link to="#" onClick={()=> { openCommentformModal();trackerShare('Comments')}} className="btn btn-success local_love_view_more_btn">Add comments</Link>
                      </Col>
                  </Row> 
                  <div className="mg-favourites mg-fav-landing">
                  <div className="tab-content">
                  <Slider className="study-slider"  {...settings} >
                      {list.map(({node}, i) => {
                            if(node.local_life_things_to_do.id == props.locallovestrapiid){
                                return (
                                  <div className={`favourite-item d-flex`}>
                                      <div className="info">
                                            <img src={CommentIconGroup} className="comment_icon_group" alt="commentIconGroup" />
                                            <ShowMoreText
                                            /* Default options */
                                            lines={5}
                                            more='More'
                                            less='Less'
                                            className='google-review-content-css desc infotext'
                                            anchorClass='btn-more review-more'
                                            expanded={false}
                                            keepNewLines={false}
                                            >

                                              {node.comments}
                                            </ShowMoreText>
                                          <h6 className="info-title">{node.name}</h6>
                                      </div>
                                  </div>
                                )
                            }
                              
                      })}
                  </Slider>
                  </div>
                  </div>
          </div>
        </div>
        <ModalTiny backdrop="static" show={modalCommentformOpen} onHide={closeCommentformModal} className="book-a-viewing-modal share-to-friend-modal">
        <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">ADD COMMENTS</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section"><CommentForm local_life_id={props.locallovestrapiid} local_life_name={props.locallovename} /></ModalTiny.Body>
      </ModalTiny>
        </div>
      );
    }
  export default LocalLifeComments