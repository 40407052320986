import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React,{useEffect, useState} from "react";
import {Col, Row, Container, Button } from 'react-bootstrap';
import VideoCard from '../VideoCard/VideoCard';
import  "./MgFavourites.scss";
import favImg01 from "../../../images/fav-img01.jpg"
import favImg02 from "../../../images/fav-img02.jpg"
import ShowMoreText from 'react-show-more-text';
import favImg03 from "../../../images/fav-img03.jpg"
import { navigate, useLocation } from "@reach/router"
import favImg04 from "../../../images/fav-img04.jpg"
//import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from "react-markdown"
import $ from "jquery"
import LikeCommentBlock from '../../LocalLoveLikeCommentBlock'

const MgFavouritesLocalLifeOthers=(props)=>{
  
  const count = props.data.length
  var type = "odd"
  if(count % 2 == 0) {
      type = "even";
  }

  const hanldeClick = () => {
    navigate('/about/coupon-code/')
  }


  return <>
        {props.data.length != 0 &&
  <section className="mg-favourites mg-fav-others other_favorutes">
    <Container>
        <div className="block">
        {/* <ScrollAnimation animateIn='fadeInUp' animateOnce> */}
        <Row>
          <Col md={8}>
              <h2 className="sec-title">Other {props.category} in {props.Title}</h2>
          </Col>
          <Col md={4}>
            <div className="d-none d-md-block view-more">
              <Link to={`../${props.url}`} className="btn btn-success">View More</Link>
            </div>
          </Col>
        </Row> 

        <div className="tab-content">
        <Row className="mg-things-do-list">
        {props.data.map(({node}, i) => {
          return (
              <Col xl={4}>
                  <div className={`favourite-item ${count == i+1 ? 'no-border' : ''} ${type == "even" ? 'no-border' : ''}`}>
                      <figure>
                          {node.Tile_Image ?  <Link to={`/about/loving-life/${node.Category.URL}/${node.URL}`} className="link"><img loading="lazy" src={node.Tile_Image.childImageSharp && node.Tile_Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${node.Name} - Martyn Gerrard`} /> </Link> : '' }
                          {node.Tile_Image_Label ? <span className="tag-abs text-uppercase">{node.Tile_Image_Label}</span> : '' }
                      </figure>
                      <div className="info">
                          <h4 className="info-title">{node.Name}</h4>
                          <span className="add-txt">{node.Address?.substring(0, 50)} {node.Address?.length > 50 ? '...' : ''}</span>

                          <LikeCommentBlock link={`../${node.URL}`} commites={props.commites} url={node.URL} strapiId={node.strapiId} name={node.Name} />
                          <ShowMoreText
                                          /* Default options */
                              lines={4}
                              more='More'
                              less='Less'
                              className='desc-content'
                              anchorClass='btn-more review-more'
                              expanded={false}
                              keepNewLines={false}
                              >

                                {node.Short_Description}
                            </ShowMoreText>
                          {/* <ReactMarkdown source={node.Short_Description} allowDangerousHtml className=""/> */}
                          <div className="business_offer_btn">
                              {
                                node.Offer_MSG && <Button onClick={hanldeClick} className="btn btn-pink" type="submit">{node.Offer_MSG}</Button>
                              }
                          </div>
                      </div>
                  </div>
              </Col>
          );
        })}
         <Col md={4}>
            <div className="d-block d-md-none view-more">
              <Link to={`../${props.url}`} className="btn btn-success">View More</Link>
            </div>
          </Col>
        </Row>
        </div>
        {/* </ScrollAnimation> */}
        </div>
    </Container>
  </section>
        }
        </>;
  }

export default MgFavouritesLocalLifeOthers
